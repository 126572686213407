<!--
* @description 智能工厂管理平台
* @fileName zngc.vue
* @author liulian
* @date 2023/06/29 16:33:22
-->
<template>
  <div class="zngc">
    <div class="z_banner">
      <img class="z_b_img" :src="$t(`zngc.banner`)" alt="banner" />
    </div>
    <div class="ptjs">
      <div class="p_con">
        <div class="z_title text-align-center font-size-36 font-weight-500 font-color-black-1D1D1F">{{ $t("zngc.section1_title") }}</div>
        <div class="p_descript font-size-18 font-weight-400">{{ $t("zngc.section1_content") }}</div>
        <div class="z_title text-align-center font-size-36 font-weight-500 font-color-black-1D1D1F">{{ $t("zngc.section2_title") }}</div>
        <div class="p_descript p_descript2 font-size-18 font-weight-400">{{ $t("zngc.section2_content") }}</div>
        <div class="p_items_box">
          <div class="p_hxnl flex-row">
            <div class="p_h_item" v-for="(item, index) in items" :key="index">
              <div class="p_h_i_left flex-column align-center">
                <img class="p_h_i_left_img" :src="item.icon" alt="图标" />
                <div class="p_h_name text-align-center font-size-18 font-weight-400 font-color-black-301D09" v-html="$t(`zngc.${item.name}`)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 核心能力 -->
    <div class="p_hxnl2">
      <div class="z_title text-align-center font-size-36 font-weight-500 font-color-black-1D1D1F">平台特点</div>
      <div class="p_p_con flex-row-between flex-wrap">
        <div class="p_p_c_item" v-for="(item, index) in hxnls" :key="index">
          <img class="p_p_c_i_img" :src="item.cover" alt="平台特点封面图" />
          <div class="p_c_name font-size-16 font-weight-400">{{ $t(`zngc.${item.name}`) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "zngc",
  components: {},
  data() {
    return {
      items: [
        {
          name: "section2_list1_item1",
          icon: require("../../../assets/images/productImg/szgc_1@2x.png"),
        },
        {
          name: "section2_list1_item2",
          icon: require("../../../assets/images/productImg/szgc_2@2x.png"),
        },
        {
          name: "section2_list1_item3",
          icon: require("../../../assets/images/productImg/szgc_3@2x.png"),
        },
        {
          name: "section2_list1_item4",
          icon: require("../../../assets/images/productImg/szgc_4@2x.png"),
        },
        {
          name: "section2_list1_item5",
          icon: require("../../../assets/images/productImg/szgc_5@2x.png"),
        },
        {
          name: "section2_list1_item6",
          icon: require("../../../assets/images/productImg/szgc_6@2x.png"),
        },
        {
          name: "section2_list1_item7",
          icon: require("../../../assets/images/productImg/szgc_7@2x.png"),
        },
        {
          name: "section2_list1_item8",
          icon: require("../../../assets/images/productImg/szgc_8@2x.png"),
        },
        {
          name: "section2_list1_item9",
          icon: require("../../../assets/images/productImg/szgc_9@2x.png"),
        },
        {
          name: "section2_list1_item10",
          icon: require("../../../assets/images/productImg/szgc_10@2x.png"),
        },
        {
          name: "section2_list1_item11",
          icon: require("../../../assets/images/productImg/szgc_11@2x.png"),
        },
        {
          name: "section2_list1_item12",
          icon: require("../../../assets/images/productImg/szgc_12@2x.png"),
        },
        {
          name: "section2_list1_item13",
          icon: require("../../../assets/images/productImg/szgc_13@2x.png"),
        },
        {
          name: "section2_list1_item14",
          icon: require("../../../assets/images/productImg/szgc_14@2x.png"),
        },
        {
          name: "section2_list1_item15",
          icon: require("../../../assets/images/productImg/szgc_15@2x.png"),
        },
      ],
      hxnls: [
        {
          name: "section3_list1_item1",
          cover: require("../../../assets/images/productImg/pttd_1@2x.png"),
        },
        {
          name: "section3_list1_item2",
          cover: require("../../../assets/images/productImg/pttd_2@2x.png"),
        },
        {
          name: "section3_list1_item3",
          cover: require("../../../assets/images/productImg/pttd_3@2x.png"),
        },
        {
          name: "section3_list1_item4",
          cover: require("../../../assets/images/productImg/pttd_4@2x.png"),
        },
        {
          name: "section3_list1_item5",
          cover: require("../../../assets/images/productImg/pttd_5@2x.png"),
        },
        {
          name: "section3_list1_item6",
          cover: require("../../../assets/images/productImg/pttd_6@2x.png"),
        },
        {
          name: "section3_list1_item7",
          cover: require("../../../assets/images/productImg/pttd_7@2x.png"),
        },
        {
          name: "section3_list1_item8",
          cover: require("../../../assets/images/productImg/pttd_8@2x.png"),
        },
        {
          name: "section3_list1_item9",
          cover: require("../../../assets/images/productImg/pttd_9@2x.png"),
        },
        {
          name: "section3_list1_item10",
          cover: require("../../../assets/images/productImg/pttd_10@2x.png"),
        },
      ],
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.zngc {
  padding-bottom: 171px;
  background-color: #fff;
  .z_b_img {
    width: 100%;
    height: 400px;
  }
  .ptjs {
    // height: 1010px;
    margin-top: -3px;
    background-color: #fff;

    .p_con {
      width: 1200px;
      padding-top: 64px;
      margin: auto;

      .p_descript {
        margin-top: 14px;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 2px;
        margin-bottom: 64px;
        min-height: 72px;
        color: rgba(29, 29, 31, 0.8);
        text-indent: 36px;
      }
      .p_descript2 {
        margin-bottom: 32px;
      }

      .p_hxnl {
        margin-top: 32px;
        flex-wrap: wrap;
        box-shadow: 0px 8px 24px 0px rgba(157, 72, 21, 0.1);
        .p_h_item {
          width: 200px;
          height: 200px;
          border: 1px solid rgba(151, 151, 151, 0.1);
          .p_h_i_left {
            margin-top: 47px;
            .p_h_i_left_img {
              width: 64px;
              height: 64px;
              margin-bottom: 16px;
            }
          }
        }
        .p_h_item:hover {
          box-shadow: 0px 8px 24px 0px rgb(255, 231, 203);
        }
        /*【负方向范围】选择第1个到第6个 */
        .p_h_item:nth-child(-n + 6) {
          border-top: 1px solid transparent;
          border-left: 1px solid transparent;
        }
        .p_h_item:nth-child(6) {
          border-right: 1px solid transparent;
        }
        /*【正方向范围】选择从第6个开始的，直到最后  */
        .p_h_item:nth-child(n + 7) {
          border-bottom: 1px solid transparent;
          border-left: 1px solid transparent;
          border-top: 1px solid transparent;
        }
      }
    }
  }
  .p_hxnl2 {
    margin-top: 32px;
    padding-top: 32px;
    background: rgba(247, 250, 247, 0.56);
    .p_p_con {
      width: 1200px;
      margin: 82px auto;
      padding-bottom: 34px;

      .p_p_c_item {
        width: 384px;
        margin-bottom: 24px;
        position: relative;
        min-height: 300px;
        background-color: #fff;
        .p_c_name {
          margin-bottom: 4px;
          line-height: 24px;
          width: 366px;
          margin: 12px auto;
          color: rgba(29, 29, 31, 0.6);
          text-align: justify;
        }
        .p_p_c_i_img {
          width: 100%;
          height: 200px;
        }
        .p_h_i_r_con {
          line-height: 24px;
          color: rgba(29, 29, 31, 0.6);
        }
      }
    }
  }
}
</style>
