import { render, staticRenderFns } from "./zngc.vue?vue&type=template&id=6a06a7fd&scoped=true&"
import script from "./zngc.vue?vue&type=script&lang=js&"
export * from "./zngc.vue?vue&type=script&lang=js&"
import style0 from "./zngc.vue?vue&type=style&index=0&id=6a06a7fd&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a06a7fd",
  null
  
)

export default component.exports